.homeHeadingContainer {
  /* border: solid; */
  height: 88vh;
  background-color: black;
  position: relative;
}
.homeHeadingContainer > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: black;
}
.homeHeadingCover {
  background-color: rgba(0, 0, 0, 0.404);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeHeadingCover > h2 {
  /* border: solid red; */
  width: 60%;
  color: white;
  font-size: 50px;
  text-align: center;
  text-shadow: 2px 2px 10px red;
  font-weight: lighter;
  margin-bottom: 200px;
}
.homeHeadingCover > h2 > span {
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeHeadingCover > h2 {
    /* border: solid red; */
    width: 90%;
    color: white;
    font-size: 40px;
    text-align: center;
    text-shadow: 2px 2px 10px red;
    font-weight: lighter;
    margin: 0;
  }
}
