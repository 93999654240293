.whyBlockContainer {
  /* border: solid blue; */
  width: 100%;
  position: absolute;
  bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}
.whyBlockCardContainer {
  /* border: solid red; */
  width: 20%;
  margin: 10px;
  height: 90px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.whyBlockCardContent1 {
  /* border: solid green; */
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whyBlockCardContent1 > img {
  width: 70%;
  height: 70%;
  object-fit: contain;
}
.whyBlockCardContent2 {
  /* border: solid brown; */
  height: 100%;
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.whyBlockCardContent2 > p {
  font-size: 25px;
  text-align: start;
  color: white;
}
@media screen and (min-width: 320px)  {
  .whyBlockContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    position: static;
    bottom: 0;
    gap: 10px;
}
  .whyBlockCardContainer {
    /* border: solid red; */
    width: 90%;
    margin: 10px;
    height: 15%;
  }
  .whyBlockCardContent2 > p {
    font-size: 25px;
    text-align: start;
    color: var(--customColor);
  }
}
@media screen and (min-width:768px) {
    .whyBlockContainer {
        /* border: solid blue; */
        width: 100%;height: 200px;
        position: static;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
      }
      .whyBlockCardContainer {
        /* border: solid red; */
        width: 40%;
        margin: 10px;
        height: 20%;
      }
    
      .whyBlockCardContent2 > p {
        font-size: 25px;
        text-align: start;
        color: var(--customColor);
      }
}
@media screen and (min-width: 990px) {
    .whyBlockContainer {
        /* border: solid blue; */
        width: 100%;
        position: absolute;
        bottom:100px;
        gap: 30px;
      }
      .whyBlockCardContainer {
        /* border: solid red; */
        width: 20%;
        margin: 10px;
        height: 40%;
      }
      .whyBlockCardContent2 > p {
        font-size: 25px;
        text-align: center;
        color: white;
      }
}