.germanBeginsContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  height: 100%;
  gap: 60px;
  background-color: white;
  margin-top: 50px;
}
.germanHeadContainer {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.germanHeadUnderline {
  border: 1px solid;
  width: 300px;
}
.germanHeadContainer > h2 {
  font-size: 30px;
  text-align: center;
}
.germanHeadContainer > h2 > span {
  color: var(--customColor);
}
.germanHeadContainer > p {
  font-size: 20px;
  text-align: center;
}
.germanBeginsMain {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.germanPointsCardsContain {
  /* border: solid blue; */
  border-radius: 10px;
  background: white;
  width: 16%;
  height: 450px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgb(173, 173, 173);
}
.pointsCardsImgContainer {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 160px;
}
.pointsCardsImgContainer > img {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pointsCardsHeadContainer {
  /* border: solid green; */
  width: 100%;
  height: 30px;
}
.pointsCardsParaContainer {
  /* border: solid brown; */
  width: 100%;
  height: 100%;
}
.pointsCardsHeadContainer > h3 {
  text-align: center;
  color: var(--customColor);
}
.pointsCardsParaContainer > p {
  text-align: justify;
  padding: 10px;
}
@media screen and (min-width: 320px) {
  .germanPointsCardsContain {
    /* border: solid blue; */
    width: 280px;
    height: 450px;
    margin: 20px;
  }
}
@media screen and (min-width: 990px) {
  .germanPointsCardsContain {
    /* border: solid blue; */
    width: 20%;
    height: 450px;
    margin: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .germanPointsCardsContain {
    /* border: solid blue; */
    width: 16%;
    height: 450px;
    margin: 20px;
  }
}
