.socialVidContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  padding: 20px;
}

.socialVidHeadContainer > h2 {
  text-align: center;
  font-size: 30px;
}
.socialVidHeadContainer > h2 > span {
  color: var(--customColor);
}
.socialVidMainContainer {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.socialVidMainContent {
  height: 100%;
  width: 40%;
  margin: 20px;
}
.socialVidCardContainer {
  /* border: solid red; */
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.respSocialVidCardContainer {
  /* border: solid red; */
  margin: 0px;
  display: none;
  justify-content: center;
  align-items: center;
}
.respSocialVidCardContainer2 {
    display: none;
    margin: 0px;
    justify-content: center;
    align-items: center;
  }

@media screen and (min-width: 320px) and (max-width: 768px) {
  .socialVidContainer {
    /* border: solid red; */
    gap: 10px;
    margin-top: 30px;
    padding: 0px;
  }

  .socialVidMainContent {
    /* border: solid pink; */
    height: 200px;
    width: 100%;
    margin: 10px;
  }
  .socialVidCardContainer {
    /* border: solid green; */
    width: 100%;
    margin: 0px;
    height: 100%;
  }
  .socialVidCardContainer {
    display: none;
  }
  .respSocialVidCardContainer {
    display: flex;
  }
  .respSocialVidCardContainer2 {
    display: none;
  }
}
@media screen and (min-width: 990px) {
  .socialVidCardContainer {
    display: none;
  }
  .respSocialVidCardContainer {
    display: none;
  }
  .respSocialVidCardContainer2 {
    display: flex;
  }
}
@media screen and (min-width: 990px) {
    .socialVidCardContainer {
      display: flex;
    }
    .respSocialVidCardContainer {
      display: none;
    }
    .respSocialVidCardContainer2 {
      display: none;
    }
  }
