.coursesStrContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.coursesStrContainer > h2 {
  /* border: solid green; */
  font-size: 30px;
  text-align: center;
}
.coursesStrContainer > h2 > span {
  color: var(--customColor);
}
.coursesStrContainer > p {
  font-size: 20px;
  padding: 10px;
}
.coursesStrContent > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.coursesStrContent > ul > li {
  /* border: solid red; */
  font-size: 20px;
  width: 70%;
  list-style: none;
  margin: 20px;
}
.coursesStrContent > ul > li > span {
  color: var(--customColor);
  font-weight: bolder;
}
.coursesStrWrapper {
  /* border: solid black; */
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.coursesStrContent {
  /* border: solid blue; */
  width: 50%;
}
.coursesStrContent > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .coursesStrContainer > p {
    font-size: 20px;
    text-align: justify;
  }
  .coursesStrContent > ul > li {
    /* border: solid red; */
    font-size: 20px;
    width:100%;
    list-style: none;
    margin: 20px;
  }
  .coursesStrWrapper {
    /* border: solid black; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
  }
  .coursesStrContent {
    /* border: solid blue; */
    width: 100%;
  }
}
