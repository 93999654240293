.b2DetailsContainer {
  /* border: solid red; */
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0px;
  background-color: var(--customColor2);
  padding: 20px;
}
.b2DetailsContainer > ul > li {
  font-size: 20px;
  margin-left: 50px;
  padding: 5px;
  color: var(--customColor);
}
.b2DetailsContainer > h3 {
  font-size: 20px;
  padding: 20px;
  color: black;
}
.b2PageMainContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.b2FlexContainer {
  /* border: solid blue; */
  padding: 20px;
}
.b2FlexContainer > h1 {
  font-size: 40px;
  text-align: center;
}
.b2FlexContainer > h1 > span {
  color: var(--customColor);
}
.b2FlexContainer > p {
  font-size: 20px;
  margin: 20px;
  color: gray;
}
.b2FlexContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .b2DetailsContainer {
    /* border: solid red; */
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .b2HeadImgContainer {
    /* border: solid red; */
    height: 80vh;
  }
  .b2HeadFormContent {
    display: none;
  }
  .respFormContainer {
    max-width: 300px;
    display: flex;
  }
  .b2HeadTextContent > h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 990px) {
  .b2HeadImgContainer {
    /* border: solid red; */
    height: 80vh;
  }
  .b2HeadImgContainer > img {
    object-fit: cover;
    object-position: 100% 0px;
  }
  .b2HeadTextContent {
    /* border: solid green; */
    width: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .b2HeadImgContainer {
    /* border: solid red; */
    height: 80vh;
  }
  .b2HeadImgContainer > img {
    object-fit: cover;
    object-position: 0px 0px;
  }
  .b2HeadTextContent {
    /* border: solid green; */
    width: 30%;
  }
}
