.examPrepContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.examPrepContent1 {
  /* border: solid blue; */
  width: 100%;
  background-color: var(--customColor2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.examPrepHeadContainer {
  /* border: solid yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.examPrepHeadContainer > h1 {
  font-size: 40px;
  text-align: center;
  color: var(--customColor);
}

.examPrepParaContainer {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background-color: var(--customColor);
  padding: 20px;
}
.examPrepParaContainer > h2 {
  width: 100%;
  text-align: center;
  color: var(--customColor2);
}
.examPrepParaContainer > p {
  font-size: 20px;
  color: var(--customColor2);
  margin: 10px;
}
.examPatternContainer {
  /* border: solid blue; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.examPatternContainer > h2 {
  font-size: 30px;
  text-align: center;
  color: var(--customColor);
  width: 100%;
}
.examPatternContainer > p {
  font-size: 20px;
  text-align: center;
}
.examPatternContainer > h3 {
  font-size: 25px;
  text-align: start;
  color: var(--customColor);
}
.examPatternContainer > ul {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px;
}
.examPatternContainer > ul > li {
  /* border: solid; */
  list-style: none;
  font-size: 20px;
  width: 20%;
  padding: 10px;
  border-radius: 60px;
  background-color: var(--customColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
}
.examPatternContainer > ul > li > h4 {
  width: 70%;
  font-size: 22px;
  text-align: center;
  color: var(--customColor2);
}
.examPatternItem {
  /* border: solid teal; */
  background-color: var(--customColor2);
  width: 30%;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.examPatternItem > img {
  width: 60%;
  height: 60%;
  object-fit: cover;
}
.examPrepMain {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* position: absolute;
  top: 300px;
  left: 0; */
}
.a1ExamPrepCover {
  border: 1px solid white;
  padding: 20px;
  width: 30%;
  height: 500px;
  margin: 20px;
  background-color: rgba(0, 0, 0, 0.404);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.a1ExamPrepCover > h3 {
  color: var(--customColor2);
  font-size: 20px;
  font-weight: bold;
}
.a1ExamPrepCover > p {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: var(--customColor);
}
.a1ExamPrepCover > ul > li {
  font-size: 20px;
  color: var(--customColor2);
  margin: 15px;
}
.examPrepPatternContent {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: var(--customColor);
  border-radius: 10px;
}
.examPrepPatternContent > h3 {
  color: white;
  font-size: 25px;
}
.examPrepPatternContent > p {
  color: white;
  font-size: 20px;
}
.examPrepContent2 {
  /* border: solid green; */
  width: 100%;
  height: 100%;
}
.examPrepContent2 > img {
  position: fixed;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
  top: 0;
}
.examPrepContent3 {
  /* border: solid black; */
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}
.examFooterContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.examFooterContainer > h3 {
  font-size: 25px;
  color: var(--customColor);
}
.examFooterContainer > p {
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .examPatternContainer > ul {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
  }
  .examPatternContainer > ul > li {
    /* border: solid; */
    list-style: none;
    font-size: 20px;
    min-width: 300px;
    padding: 10px;
    border-radius: 60px;
    background-color: var(--customColor);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
  }
  .a1ExamPrepCover {
    border: 1px solid white;
    padding: 20px;
    min-width: 300px;
    height: 100%;
    margin: 20px;
    background-color: rgba(0, 0, 0, 0.404);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
  }
}
