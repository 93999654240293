.prizmaMapContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.map {
  width: 100%;
  height: 500px;
  border: none;
  outline: none;
}
