.footerContainer {
  /* border: solid red; */
  background-color: var(--customColor2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer1Container {
  /* border: solid blue; */
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px;
}
.footer1Content {
  /* border: solid green; */
  width: 20%;
}

.footer1Content > ul > h3 {
  color: var(--customColor);
}
.footer1Content > ul > li {
  list-style: none;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin: 10px;
}
.footer1Content > ul > li > a {
  color: black;
  text-decoration: none;
}

#footer1Content5 > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 40px;
}
#footer1Content5 > ul > li {
  margin: 20px;
}
.footerIcon {
  font-size: 30px;
}
.footerIcon:hover {
  color: var(--customColor);
}
.footerUnderline {
  border: 1px solid var(--customColor);
  width: 80%;
}
.footer2Container {
  /* border: solid blue; */
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}
.footer2Container > ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.footer2Container > ul > li {
  list-style: none;
  font-size: 20px;
}
.footer2Container > ul > li > a {
  color: var(--customColor);
  text-decoration: none;
}
.footer2Container > ul > div {
  /* border: solid; */
  width: 130px;
  height: 80px;
  border-radius: 20px;
}
.footer2Container > ul > div > a > img {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transform: scale();
}
.footer2Container > ul > li > h6 {
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .footer1Container {
    /* border: solid blue; */
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }
  .footer1Content {
    /* border: solid green; */
    width: 100%;
  }
  .footer2Container > ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
}
