.contactUsHead {
  /* border: solid black; */
  width: 100%;
  height: 70vh;
  object-position: 0, 0;
  position: relative;
}
.contactUsCover {
  /* border: solid; */
  position: absolute;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.contactUsCover > h2 {
  font-size: 40px;
  color: white;
}
.contactUsHead > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactUsContainer {
  /* border: solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
}
.contactUsContent1 {
  /* border: solid blue; */
  width: 40%;
}
.contactUsContent2 {
  /* border: solid green; */
  width: 30%;
}
.contactUsContent3 {
  /* border: solid brown; */
  width: 100%;
  height: 100%;
}
.contactInfoContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.contactInfoContent {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 20px;
}
.contactInfoItem1 {
  /* border: solid blue; */
  width: 20%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactInfoItem1 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 0, 0;
  padding: 10px;
}
.contactInfoItem2 {
  /* border: solid blue; */
  width: 80%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contactInfoItem2 > p {
  font-size: 20px;
}
.contactInfoItem2 > p > span {
  color: var(--customColor);
}
.contactInfoItem2 > p > a {
  font-size: 20px;
  text-decoration: none;
  color: var(--customColor);
}
.contactUsFormContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.contactUsFormContainer {
  /* border: solid red; */
  height: 550px;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.contactUsFormContainer > h2 {
  color: var(--customColor);
}
.contactUsFormContainer > p {
  text-align: center;
}
.contactUsFormContainer > form {
  /* border: solid red; */
  width: 100%;
  height: 100%;
}
.contactUsFormContainer > form > fieldset {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contactUsFormContainer > form > fieldset > legend {
  font-size: 30px;
  text-align: center;
  color: var(--customColor);
  /* border: solid black; */
  padding: 20px;
  font-family: "customFont", sans-serif;
}
.contactUsFormContainer > form > fieldset > table {
  /* border: solid yellow; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.contactUsFormContainer > form > fieldset > table > tr {
  /* border: solid purple; */
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
}

.contactUsFormContainer > form > fieldset > table > tr > td {
  border-bottom: solid grey;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactUsFormContainer > form > fieldset > table > tr > td > input {
  font-size: 100%;
  width: 90%;
  height: 90%;
  border: none;
  outline: none;
}
.contactUsFormContainer > form > fieldset > table > tr > td > textarea {
  border: none;
  outline: none;
  width: 90%;
  color: grey;
  font-size: 100%;
}

.contactUsFormContainer > form > fieldset > table > button {
  border: solid var(--customColor);
  width: 50%;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  background-color: white;
  color: white;
  background-color: var(--customColor);
  text-align: center;
  transition: 200ms ease-in-out;
}
.contactUsFormContainer > form > fieldset > table > button:hover {
  color: var(--customColor);
  background-color: white;
  cursor: pointer;
}
.contactUsFormContainer {
  font-size: 20px;
}
.sub > a {
  color: white;
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .contactUsContainer {
    /* border: solid red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
  }
  .contactUsContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
  }
  .contactUsContent2 {
    /* border: solid green; */
    width: 100%;
    height: 100%;
  }

  .contactInfoContainer {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .map{
    width: 100%;
  }
}
