.testimonialContainer {
  /* border: solid red; */
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin: 0px;
}
.testimonialHead {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}
.testimonialHead > h2 {
  text-align: center;
  width: 100%;
  font-size: 30px;
  color: var(--customColor);
}
.testimonialHead > p {
  font-size: 20px;
  color: grey;
}
.testimonialMainContainer {
  /* border: solid green; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .testimonialSlider {
  border: solid;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonialSliderContent {
  border: solid red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.testimonialCardContainer {
  border: solid transparent;
  height: 100%;
  width: 30%;
  position: relative;
  margin: 30px;
}
.testimonialBorder1 {
  border-top: solid var(--customColor);
  position: absolute;
  width: 40px;
  left: 0;
}
.testimonialBorder2 {
  border-left: solid var(--customColor);
  position: absolute;
  height: 40px;
  left: 0;
}
.testimonialBorder3 {
  border-bottom: solid var(--customColor);
  position: absolute;
  width: 40px;
  right: 0;
  bottom: 0;
}
.testimonialBorder4 {
  border-left: solid var(--customColor);
  position: absolute;
  height: 40px;
  right: 0;
  bottom: 0;
}
.testimonialCardContainer > p {
  /* border: solid ; */
  margin: 20px;
  font-size: 20px;
  text-align: justify;
}
.testimonialCardContainer > p > span {
  color: var(--customColor);
}
.testimonialFooter {
  /* border: solid blue; */
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .testimonialContainer {
    /* border: solid red; */
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    margin: 0px;
  }
  .testimonialMainContainer {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .testimonialCardContainer {
    border: solid transparent;
    height: 100%;
    width: 90%;
    position: relative;
    margin: 30px;
  }
}
