.courseDetailsContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.CourseDetailsHead {
  border: solid blue;
  width: 100%;
}
.CourseDetailsHead > h2 {
  border: solid blue;
  font-size: 30px;
  text-align: center;
}
.courseDetailsContent {
  border: solid;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.courseDetailsMainContainer {
  border: solid brown;
  width: 100%;
}
.courseDetailsDisplay {
  border: solid green;
  width: 80%;
  height: 100%;
}

.a1DetailsContainer > ul > li,
.a2DetailsContainer > ul > li {
  font-size: 20px;
  margin-left: 50px;
  padding: 5px;
}
.a1DetailsContainer > h3,
.a2DetailsContainer > h3 {
  font-size: 20px;
  color: var(--customColor);
}
