.a2HeadImgContainer {
  /* border: solid red; */
  height: 80vh;
  position: relative;
  /* background-color: #8fab9d; */
}
.a2HeadImgContainer > img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  object-position: 0px 0px;
}
.a2HeadTextContent {
  /* border: solid green; */
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 40%;
  left: 0;
  width: 30%;
  height: 30%;
  background-color: #e91c1cc1;
}
.a2HeadTextContent > h2 {
  color: white;
  margin-left: 10px;
  font-size: 40px;
}

.a2HeadFormContent {
  /* border: solid blue; */
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  object-fit: cover;
}
.a2HeadContent1 > h2 {
  /* border: solid green; */
  font-size: 30px;
  text-align: center;
  padding: 30px;
}
.a2HeadContent1 > h2 > span {
  color: var(--customColor);
}
.a2HeadContent2 {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .a2TrainContainer {
  border: solid blue;
  width: 20%;
  height: 90%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 0 10px rgb(153, 153, 153);
}
.a2TrainContainer > img {
  width: 25%;
}
.a2TrainContainer > h3 {
  font-size: 20px;
} */
.a2DetailsContainer {
  /* border: solid red; */
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0px;
  background-color: var(--customColor2);
  padding: 20px;
}
.a2DetailsContainer > ul > li {
  font-size: 20px;
  margin-left: 50px;
  padding: 5px;
  color: var(--customColor);
}
.a2DetailsContainer > h3 {
  font-size: 20px;
  padding: 20px;
  color: black;
}
.a2PageMainContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.a2FlexContainer {
  /* border: solid blue; */
  padding: 20px;
}
.a2FlexContainer > h1 {
  font-size: 40px;
  text-align: center;
}
.a2FlexContainer > h1 > span {
  color: var(--customColor);
}
.a2FlexContainer > p {
  font-size: 20px;
  margin: 20px;
  color: gray;
}
.a2FlexContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.a2HeadRespForm {
  /* border: solid; */
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .a2HeadImgContainer {
    /* border: solid red; */
    height: 40vh;
  }
  .a2HeadImgContainer > img {
    object-fit: cover;
    width: 100%;height: 100%;
    object-position: 40% 0;
  }
  .a2HeadFormContent {
    display: none;
  }
  .a2HeadTextContent {
    /* border: solid green; */
    top: 40%;
    left: 0;
    width: 60%;
    height: 30%;
  }
  .a2HeadTextContent > h2 {
    font-size: 20px;
  }
  .a2HeadRespForm {
    display: flex;
  }

  .respFormContainer {
    max-width: 300px;
    display: flex;
  }
  .a2HeadContent2 {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .a2TrainContainer {
    /* border: solid blue; */
   min-width: 300px;
    flex-wrap: wrap;
    box-shadow: 0 0 10px rgb(153, 153, 153);
  }
  .a2TrainContainer > h3 {
    font-size: 20px;
    padding: 20px ;
  }
  .a2DetailsContainer {
    /* border: solid red; */
    width: 100%;
  }
}
@media screen and (min-width:768px) and (max-width:990px) {
  .a2HeadImgContainer {
    /* border: solid red; */
    height: 80vh;
  }
  .a2HeadFormContent {
    display: none;
  }
  .respFormContainer {
    max-width: 300px;
    display: flex;
  }
  .a2HeadTextContent > h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 990px) {
  .a2HeadImgContainer {
    /* border: solid red; */
    height: 80vh;
  }
  .a2HeadImgContainer > img {
    object-fit: cover;
    object-position: 100% 0px;
  }
  .a2HeadTextContent {
    /* border: solid green; */
    width: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .a2HeadImgContainer {
    /* border: solid red; */
    height: 80vh;
  }
  .a2HeadImgContainer > img {
    object-fit: cover;
    object-position: 0px 0px;
  }
  .a2HeadTextContent {
    /* border: solid green; */
    width: 30%;
  }
}
