.coursesBlockContainer {
  /* border: solid red; */
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.coursesBlockContent {
  /* border: solid blue; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  z-index: 1;
  top: -100px;
}

.coursesBlockCardContainer {
  /* border: solid green; */
  background-color: white;
  height: 175px;
  width: 260px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  border-radius: 20px;
  box-shadow: 0px 10px 10px 5px rgb(132, 130, 130);
  transition: 300ms ease-in-out;
}
.coursesBlockCardContainer:hover {
  transform: scale(1.1);
 background-color : var(--customColor);
}
.coursesBlockCardContent {
  border: 1px solid var(--customColor);
  width: 15%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
}

.coursesBlockCardContent > p {
  text-align: center;
  color: var(--customColor);
}
.coursesBlockCardContainer > h4 {
  font-size: 20px;
}
.coursesBlockCardContainer > h4 > a {
  color: black;
  font-weight: lighter;
  text-decoration: none;
}
.coursesBlockCardContainer > p > a {
  color: grey;
  text-decoration: none;
}
.coursesBlockCardContainer:hover .coursesBlockCardContent{
border: 1px solid white;
}
.coursesBlockCardContainer:hover .coursesBlockCardPara>a {
  color: white;
}
.coursesBlockCardContainer:hover .coursesBlockCardPara {
  color: white;
}
.coursesBlockButton {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
}
.coursesBlockButton > button {
  border: none;
  height: 50px;
  width: 180px;
  border-radius: 50px;
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--customColor);
  color: white;
  cursor: pointer;
}
.coursesBlockButton > button > span {
  font-size: 30px;
}
@media screen and (min-width: 320px) {
  .coursesBlockContainer {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    margin-top: 650px;

  }
  .coursesBlockContent {
    /* border: solid blue; */
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: static;
    z-index: 1;
    top: 0;
  }
}
@media screen and (min-width: 768px) {
  .coursesBlockContainer {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    margin-top: 250px;

  }
  .coursesBlockContent {
    /* border: solid blue; */
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: static;
    z-index: 1;
    top: 0;
  }
}
@media screen and (min-width: 990px) {
  .coursesBlockContainer {
    /* border: solid red; */
    width: 100%;
    height: 900px;
    margin-top: 0;
  }
  .coursesBlockContent {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
    top: -100px;
  }
}
@media screen and (min-width: 1200px) {
  .coursesBlockContainer {
    /* border: solid red; */
    width: 100%;
    height: 900px;
  }
  .coursesBlockContent {
    /* border: solid blue; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
    top: -100px;
  }
}
