.lvlBenefitsContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  background-color: var(--customColor2);
}
.lvlBenefitsHead {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.lvlBenefitsHead > h2 {
  font-size: 30px;
  text-align: center;
}
.lvlBenefitsHead > h2 > span {
  color: var(--customColor);
}
.lvlBenefitsHead > p {
  font-size: 20px;
  text-align: center;
  width: 90%;
}
.LvlBenefitsMainContainer {
  /* border: solid green; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LvlBenefitsMainContainer > ul > li {
  list-style: none;
  font-size: 20px;
  margin: 30px;
  color: grey;
}
.LvlBenefitsMainContainer > ul > p {
  font-size: 25px;
  text-align: center;
  color: var(--customColor);
}
.LvlBenefitsMainContainer > ul > li > span {
  font-size: 20px;
  color: var(--customColor);
  font-weight: bold;
}
.LvlBenefitsMainContainer > ul>ul>h3{
    color: var(--customColor);
    font-size: 20px;
    margin-left: 30px;
}
.LvlBenefitsMainContainer > ul>ul>li{
    margin-left: 60px;
    font-size: 20px;
    line-height: 40px;
}
@media screen and (min-width:320px) and (max-width:767px) {
  .lvlBenefitsContainer {
    /* border: solid red; */
    padding: 20px;
  }
  .LvlBenefitsMainContainer {
    /* border: solid green; */
    width: 100%;

  }
}